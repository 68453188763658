.test-display { 
    background-color: black; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    user-select: none;
}
.test-display .frame { 
    border: 15px solid #00ff00; 
    color:#00ff00; 
    font-family: LucidaConsole, 'Courier New', Courier, monospace; 
    font-size: 72px;
    padding:20px 0;
    width: 720px;
    text-align: center;
    white-space: nowrap;
}
.test-display .frame-SECRET { font-weight: normal; }
.test-display .frame-MASK { font-weight: bold; }
.test-display .frame-WORD_FOR_ACTION { font-weight: bold;}
.test-display .frame-WORD_FOR_VIEW { font-weight: bold; }
.test-display .frame-error { 
    border: 15px solid red; 
    color:black; 
    background: red;
    font-weight: bold;
}