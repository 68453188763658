.cover-wrapper {
    background-color: '#7f759a';
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    min-height: 100vh;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.cover-view { 
    background: #fff;
    max-width: 230px;
    width: 100%;


    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px 40px 40px; 
    text-align:center;
    border-radius:10px;
}
.psy-logo { 
    margin:20px; 
}
.cover-wrapper .footer {
    text-align:center;
    color: #fff;
}